/* Project-specific styles */

form button {
    min-width: 100px;
    margin: 0 1px;
}

.presto-dialog input.form-control {
    height: auto;
}

.tab-content .input-group {
    padding-left: 15px;
    padding-right: 15px;
}

.presto-consumer-request-photo {
    width: 80px;
    height: 50px;
    padding-right: 5px;
}

.presto-dialog .presto-fileDrag {
    margin-left: 15px;
}

.tab-content span.k-datepicker {
    width: 100%;
}


.tab-content span.k-datetimepicker {
    width: 100%;
}

.presto-dragging {
    background-color: #DD4B39;
}

.presto-dragging-grid tbody tr {
    cursor: move;
}

.presto-dialog select {
    height: 32px;
}

.fas {
    width: 20px;
}

.fab {
    width: 20px;
}

.k-widget span.custom-file-input-wrapper {
    margin-top: 20px;
}

.k-datepicker .k-picker-wrap {
    height: 34px;
}

.k-datepicker .k-picker-wrap .k-input {
    height: 100%;
}

.k-window {
    border-radius: 4px;
}

.k-window-titlebar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.btn-u {
    border-style: unset;
    background-color: transparent;
}

.btn-a {
    color: #3c8dbc;
    text-decoration: none;
    border-style: unset;
    background-color: transparent;
}

.btn-a:hover,
.btn-a:active,
.btn-a:focus {
    outline: none;
    text-decoration: none;
    color: #72afd2;
}

.k-button {
    border-radius: 4px;
    margin-right: 2px;
    margin-left: 2px;
}

.k-button:focus,
.k-button:hover {
    text-decoration: none;
    outline: 0;
}

.k-button.k-state-hover,
.k-button:hover {
    color: #2e2e2e;
    border-color: #b6b6b6;
    background-color: #bcb4b0;
}

.k-button-icon {
    border-style: unset;
    padding: 2px 3.5px;
    width: calc(1.72em + 6px);
}

.k-gantt-toolbar .k-state-default,
.k-grid .k-grouping-header,
.k-grid-header,
.k-grid-header-wrap,
.k-grouping-header .k-group-indicator,
.k-header,
.k-pager-wrap,
.k-pager-wrap .k-link,
.k-pager-wrap .k-textbox {
    border-color: #d5d5d5;
}

.k-grid-toolbar {
    border-color: #c5c5c5;
    border-width: 0 0 1px;
    display: flex;
}

.k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
    color: #000000;
    border-radius: 1.0833em;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
    color: #424242;
    background-color: #bcb4b0;
}

.k-pager-wrap .k-pager-nav {
    color: #2e2e2e;
    border-color: #b6b6b6;
    border-style: solid;
    border-width: 1px;
    border-radius: 1.0833em;
    cursor: pointer;
    text-align: center;
    margin: 1px;
}

.k-pager-wrap .k-pager-nav:hover {
    background-color: #bcb4b0;
}

.k-pager-numbers .k-link.k-state-selected {
    border-style: solid;
    border-width: 1px;
    text-align: center;
    border-radius: 1.0833em;
    color: #fff;
    background-color: #f35800;
    border-color: #f85a00;
}

.k-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 100%;
    list-style: none
}

.k-listbox {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 200px;
    width: 100%;
}

.k-listbox.k-widget {
    background-color: transparent
}

.k-list .k-item {
    justify-content: center;
}

.k-listbox .k-item:hover:not(.k-state-disabled) {
    color:#2e2e2e;
    background-color:#bcb4b0;
    border-color:transparent
}

.k-listbox .k-list-scroller {
    height: 100%;
    overflow: auto;
    border-width: 1px;
    border-style: solid;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.k-listbox .k-list-scroller li {
    cursor: default;
    -webkit-touch-callout: none
}

.k-listbox .k-listbox-toolbar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.k-listbox.k-listbox-toolbar-top {
    padding: 15px 0 1.42857143em
}

.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar {
    margin-top: -15px;
    margin-bottom: 5px
}

.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar li {
    display: inline-block
}

.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar li+li {
    margin-left: 5px
}

.k-listbox.k-listbox-toolbar-left .k-listbox-toolbar {
    float: left;
    margin-right: 5px
}

.k-listbox.k-listbox-toolbar-left .k-listbox-toolbar li+li {
    margin-top: 5px
}

.k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
    float: right;
    margin-left: 5px
}

.k-listbox.k-listbox-toolbar-right .k-listbox-toolbar li:not(:last-child) {
    margin-bottom: 5px
}

.k-listbox.k-listbox-toolbar-bottom {
    padding: 15px 0 1.45em
}

.k-listbox.k-listbox-toolbar-bottom .k-list-scroller {
    margin-top: -15px
}

.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar {
    margin-top: 5px
}

.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar li {
    display: inline-block
}

.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar li+li {
    margin-left: 5px
}

.k-listbox .k-list-scroller .k-list {
    height: auto;
    background: transparent;
}

.k-listbox .k-ghost {
    opacity: .5
}

.k-dialog-titlebar {
    color: #2e2e2e;
    background-color: transparent;
}

.k-dialog-buttongroup {
    justify-content: end;
}

.presto-dialog .input-group .form-control {
    box-sizing: content-box;
}

.presto-security-listbox .k-listbox {
    width: auto;
}

.presto-security-listbox .k-list-scroller {
    width: 150px;
}

.presto-access-right-listbox .k-listbox {
    width: auto;
    height: 350px;
}

.presto-access-right-listbox .k-list-scroller {
    width: 400px;
}

.presto-user-listbox {
    height: 380px;
}

.presto-user-listbox .k-listbox {
    height: 380px;
}

.presto-user-in-security-listbox .k-listbox {
    width: auto;
}

.presto-user-in-security-listbox .k-list-scroller {
    width: 400px;
}

.presto-consumer-request-datepicker .k-datepicker {
    width: 100%;
}

.main-header .logo {
    font-size: 18px;
}

.selector-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    overflow: hidden;
    z-index: 10003;
}

.selector-wrapper .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    background-color: rgba(0, 0, 0, 0.5);
}

.k-dialog-window .k-window-content {
    padding: 8px 8px;
}

.k-dialog-loader {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.k-dialog-loader .k-overlay {
    background-color: transparent;
}

.k-dialog-loader .k-dialog {
    background-color: transparent;
}

.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
}

.nav > li {
    position: relative;
    display: block;
}

.tab-content {
    min-width: 800px;
}

.admin-accounts-tab-content {
    width: 70%;
    height: 500px;
    overflow-x: hidden;
}

.master-merchant-tab-content {
    width: 1000px;
}

.payment-merchant-tab-content {
    width: 1000px;
}

.group-users-tab-content {
    min-width: 500px;
    width: 500px;
    height: 400px;
}

.nav-tabs-custom > .nav-tabs > li > button {
    margin: 10px;
    color: #444;
}

.nav-tabs-custom > .nav-tabs > li.active {
    border-right: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
}

.nav-tabs-custom > .nav-tabs > li.active > button {
    border-top-color: transparent;
    border-left-color: #f4f4f4;
    border-right-color: #f4f4f4;
}

.nav-tabs-custom > .nav-tabs > li:first-of-type.active > button {
    border-left-color: transparent;
}

.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type > button {
    border-left-width: 1px;
}

.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type.active > button {
    border-left-color: #f4f4f4;
    border-right-color: transparent;
}

@media only screen and (min-width: 300px) {
    .k-pager-wrap .k-pager-numbers-wrap select {
        display: none;
    }
}

.k-grid {
    border-color: #d5d5d5;
}

.k-grid-norecords {
    display: contents;
}

.k-grid-header .k-header {
    vertical-align: top;
}

.k-grid th {
    white-space: inherit;
}

.alert.form-group {
    margin-left: 2px;
    margin-right: 2px;
}

.alert-danger ul {
    list-style: none;
    display: contents;
}

.captcha-image {
    width: 100%;
    height: 100%;
}

.captcha-btn-refresh {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: 4px;
}

.img-rectangle {
    border-radius: 3px;
}

.point-management-create-content {
    height: 550px;
    overflow-x: hidden;
}

.point-management-details-content {
    width: 70%;
    height: 550px;
    overflow-x: hidden;
}


  .logo-image {
    height: 125px;
    
   }

   .color-circle {
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px; /* Add spacing between the circle and text */
    
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;

  }